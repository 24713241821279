import { Analytics, JestMock, IAnalytics, LinkTypes } from '@shared/analytics';
export type { LinkTypes } from '@shared/analytics';

export type RecordRedirect = (linkType: LinkTypes, href: URL) => Promise<void>;

/**
 * @deprecated superseded by useAnalytics hook
 */
let instance: IAnalytics;

if (process.env.NODE_ENV === 'test') {
    instance = new JestMock();
} else {
    if (!process.env.NEXT_PUBLIC_CADEMY_API_URL) {
        console.error(new Error('Missing API env'));
    }
    const baseURL = process.env.NEXT_PUBLIC_CADEMY_API_URL || 'https://api.cademy.co.uk';
    instance = new Analytics({
        baseUrl: new URL(baseURL),
        segmentApiKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY,
        recordAgainst: 'marketplace',
    });
}

export default instance;
